<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-4">
          Imprint
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <b>Widerstand und Söhne GmbH</b>
        <br>Prittwitzstr. 100
        <br>89075 Ulm
        <br>Germany
        <br>
        <br>Legislation: Amtsgericht Ulm
        <br>Business register: HRB 734906
        <br>VAT ID: DE311314354
        <br>
        <br><b>CEO</b>
        <br>Dirk Bayer
        <br>Falko Pross
        <br>Manuel Schall
        <br>
        <br><b>Responsible for the contents of this website</b>
        <br>Dirk Bayer
        <br>Falko Pross
        <br>Manuel Schall
        <br>
        <br><b>Contact</b>
        <br><a href="mailto:info@ulmernest.de">info@ulmernest.de</a>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
