<template>
  <div>
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-10">
          Thank you!
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          The Ulmer Nest project wouldn't have been possible without a lot of external help and support. This is why we want to say Thank You to:
        </p>
        <ul>
          <li>
            Explicitly to Holger Hördt and Franziska Vogel from the social department of Ulm municipality, who initiated the project, and who are advancing it with great commitment since.
          </li>
          <li>
            To the Ulm city council, for the trust and the possibility to investigate the Ulmer Nest concept further.
          </li>
          <li>
            To the generation fund of the Sparkasse Ulm for their financial support, which gives us the possibility to ensure a proper scientific evaluation of the results.
          </li>
          <li>
            To Nuki Home Solutions for providing us with equipment for our prototypes.
          </li>
          <li>
            To Edeka Dörflinger and Norbert Lins for donating the outcome of their "Kassieren für den guten Zweck" campaign to the Ulmer Nest.
          </li>
          <li>
            To Digitale Agenda Ulm for considering the Ulmer Nest for the Smart City Project and therefore make it possible to integrate our prototypes into Ulm's own LoRaWan-Network.
          </li>
          <li>
            To Barz GmbH for cleaning the Ulmer Nester free of charge in 2021.
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'thanks',
  components: {
  }
}
</script>
