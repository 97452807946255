<template>
  <component :is="computedComponent"></component>
</template>

<script>

import FAQ from '@/views/FAQ'
import FAQEn from '@/views/FAQ_en'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    FAQ,
    FAQEn
  },
  computed: {
    ...mapGetters([
      'language'
    ]),
    computedComponent () {
      if (this.language === 'de') {
        return 'FAQ'
      } else {
        return 'FAQEn'
      }
    }
  }
}
</script>
