<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-10">
          Herzlich Willkommen!
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Vielen Dank für Ihr Interesse an diesem Projekt. Das Ulmer Nest ist ein Pilotprojekt, bei dem neue Wege zur Unterstützung bereits vorhandener Maßnahmen zum Erfrierungsschutz gefunden und erprobt werden sollen.
        </p>
        <p>
          Das Projekt entstand im Rahmen einer Anfrage der Stadt Ulm für die temporäre Problemlösungs-Initiative "Wilhelmsbüro" der beiden jungen Ulmer Unternehmen Bootschaft, Widerstand und Söhne und Informatiker Florian Geiselhart. Von diesem Projektteam, bestehend aus Produkt- und Interface-Designern, Software- und Hardware-Entwicklern, wurde das Ulmer Nest initiiert und bis zum heutigen Stand entwickelt.
        </p>
        <p>
          Auf diesen Seiten erhalten Sie umfassende Informationen über die Entstehung, das Konzept und die erste Erprobungsphase des Ulmer Nests. Sollten Sie weitere Fragen haben, fühlen Sie sich gerne frei uns zu kontaktieren.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
