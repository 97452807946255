<template>
  <component :is="computedComponent"></component>
</template>

<script>

import Home from '@/views/Home'
import HomeEn from '@/views/Home_en'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    Home,
    HomeEn
  },
  computed: {
    ...mapGetters([
      'language'
    ]),
    computedComponent () {
      if (this.language === 'de') {
        return 'Home'
      } else {
        return 'HomeEn'
      }
    }
  }
}
</script>
