<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-4">
          Frequently Asked Questions (FAQ)
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="title mb-3 mt-6">
          How much does an Ulmer Nest cost? How can I buy one?
        </div>
        <p>
          As there are only two Nests existing right now, which are mostly hand-crafted prototypes for evaluating our concept, we are neither able to give you a proper quote at this point, nor can we sell readymade Nests.
          We are still optimizing the construction also in terms of cost, with the goal of pushing the total material cost below a sane number, as well as being able to serially produce the shelters at some point in the future.
        </p>
        <p>
          However, if you are a representing a municipal office, NGO or company looking willing to deploy a solution like the Ulmer Nest, you can get in touch so we can keep you updated with the progress.
        </p>
        <p>
          Please also consider that while initial costs for buying Nest might be relevant, there also continuous costs stemming from the concept which you should consider as well – for cleaning, infrastructue, maintenance and especially social work. Only when considering this as a whole, implementing a concept like the Ulmer Nest in your city does make sense.
        </p>

        <div class="title mb-3 mt-6">
          Are there build plans or instructions to help me build a Nest myself?
        </div>
        <p>
          Right now, we don't have any plans or instructions, and also won't be able to provide those. This is mainly due to the fact that while evaluating our existing prototypes, we discover opportunities for improvement almost every day, so plans would be changing rapidly.
        </p>
        <p>
          However, for now we explicitly want to encourage you to still going further with your ideas and momentum that you might have. If we can help by answering any questions along that way, or providing you with best practises from what we've learned so far, we will be happy to do so!
        </p>
        <div class="title mb-3 mt-6">
          I want to get involved with the Ulmer Nest business-wise, how can I get in touch?
        </div>
        <p>
          If you have specific knowledge or capabilities in manufacturing, or a business (model) that allows you to invest in a good cause which is not entirely profit-oriented, and would like to support us, please get in touch via email at business@ulmernest.de.
        </p>
        <div class="title mb-3 mt-6">
          Where do the costs for the Nest that can be found in the media stem from?
        </div>
        <p>
          Right now, we are still in a prototyping and evaluation phase. Therefore,the actual price that was given in various media outlets is way higher than it would be for a finished product, as it includes a lot of development and testing work, and countless hours of assembling the Nests by hand. In a possible serial product, the price will dramatically reduce from the numbers given in the media.
        </p>
        <p>
          The numbers given in the media include not only materials, but also development, production, sensors, hours of coding, setup and teardown including transport, maintenance, cleaning and social work, the latter being an integral part of the concept. So basically all the costs for one winter season and the prototypes themselves.
        </p>
        <div class="title mb-3 mt-6">
          Who's behind the Ulmer Nest?
        </div>
        <p>
          Contrary to occasional media reports, the Ulmer Nest is absolutely not meant to be an "art project", but the result of a collaboration of six Ulm-based entrepreneurs with broad experience in design and development of products and solutions, always following a user-centric approach.
          This unique combination of knowledge allows us to cover all the aspects of the Nest, from material choices or electronics up to software development.
          And if there's a need for additional competences, we are able to access a broad network of local experts for almost any task at hand.
        </p>
        <div class="title mb-3 mt-6">
          Why not rather donate the money to charity organizations to support the poor?
        </div>
        <p>
          The Ulmer Nest is a means of supporting a specific group of homeless people, namely those who can't go to a regular homeless shelter for various reasons. This can be due to physical or mental issues, but also because people are not necessarily registered in Ulm, or simply because they have a dog, and don't want to spend the night separated.
          Without something like the Ulmer Nest, this group is basically unprotected from the cold, and has to sleep outside even during the coldest nights, without any protection from harm.
        </p>
        <div class="title mb-3 mt-6">
          Why not use the money to created additional, real housing for those people?
        </div>
        <p>
          The Ulmer Nest is neither meant nor designed to be an alternative to regular housing. It's a means of last resort, which is designed to protect people from physical harm or even freezing to death during the coldest nights - much like a weather shelter.
        </p>
        <div class="title mb-3 mt-6">
          How do you make sure that the Nests are being used only by those in need, and are not abused by other people?
        </div>
        <p>
          Theres various measures in place to make sure that the Nest aren't being abused by others. This means regular checks, locking the Nests above a certain temperature or during the daytime and so on.
          But in the end, there's no guarantee - and we deliberatly take the risk of occasional other guests, to be able to provide a low-threshold means of support to those who are in real need.
        </p>
        <div class="title mb-3 mt-6">
          Wouldn't it make more sense to help the affected persons get into regular housing than supporting their homeless life by something like the Nests?
        </div>
        <p>
          Yes – the final goal should always be to get people back into real housing and help them to keep it that way. However, this is a continuous, sometimes slow process, and the Nests are meant as a means of support in the very early phases of this process. Not only do they help people to literally survive, but they also create a new contact point where social workers can meet those people, and try to trigger the very first steps of this process.
        </p>
        <div class="title mb-3 mt-6">
          Are there any guarantees that the Ulmer Nest will help people to survive, no matter what the outside conditions are?
        </div>
        <p>
          No, even in a Nest, dangerously low temperatures can happen. The Nest is primarily made to keep its user from humidity, rain or wind, and also uses the generated body heat to create a temperature level slightly above the outside level. It is an alternative to sleeping outside, but not comparable to sleeping in a real homeless shelter. It is a measure of last resort.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
