<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-4">
          How it all started...
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="title mb-3 mt-6">
          Wilhelmsbüro
        </div>
        <p>
          The Ulmer Nest was created within the Wilhelmsbüro project, which took place in fall 2018 as a part of Ulm's "Stürmt die Burg" project, which was meant to revitalise Ulms century-old Wilhelmsburg (Wilhelms's castle) and its newly renovated rooms.
          <br>
          The Wilhelmsbüro consists of two Ulm-based startup companies (the Bootschaft design bureau based in Neu-Ulm and software-/hardware developers Widerstand und Söhne), as well as the Ulm based digital product developer Florian Geiselhart.
          <br>
          The team of six wanted to put the castle to test as a future place for startups and other small and innovative companies, by challenging themselves with a wealth of different "problems" submitted by citiziens, companies and institutions over the course of five weeks.
        </p>
        <div class="video-container">
          <iframe src="https://player.vimeo.com/video/296739281?byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
        <div class="title mb-3 mt-6">
          The Task
        </div>
        <p>
          In the third session, the city of Ulm provided us with the task of creating a freezing prevention solution for homeless people in Ulm.
          We didn't know anything about the challenge beforehand, and from the announcement of what our goal should be until the solution presentation, we had less than 48 hours of time.
        </p>
        <div class="title mb-3 mt-6">
          Our Approach
        </div>
        <p>
          As it is often the case with projects, none of use had any real experience with the topic of homelessness, so our very first task was to research and collect information from as many perspectives on this topic as possible.
          <br>
          We talked to a lot of people, including Franziska Vogel, who was the one to submit the idea to us from the city of Ulm and their social department, Sabine Schwarzenböck as the sponsor of the project, Karin Ambacher from the local DRK shelter, Rainer Türke from the public order office of the city and Sascha Wagner from mobile social work in Ulm.
        </p>
        <div class="title mb-3 mt-6">
          Insights
        </div>
        <p>
          We analyzed our research and gained a lot of insights, with one main insight – there are already means of cold protection in Ulm, but they are not accessible for the specific group of people we were targeting.
          The reasons ranged from mental issues preventing them being in a cramped room with a lot of other people, the fear of crime and violence, but also a lot of regulations and laws. For example, dogs are not allowed to the regular shelters, intoxicated people neither, nor people who cannot prove their formal residence in the city.
        </p>
        <p>
          Following a double diamond approach, we tried to find the most appropriate solution among a wealth of ideas we generated: interim use of empty spaces, container villages or online tools to connect people in need with volunteers.
        </p>
        <p>
          In the end, it was clear that we had to put our focus on the people which are not able to use the current measures for cold protection. We wanted to create something that helps them not being forced anymore to sleep outside and risk their lifes in doing so.
        </p>
        <p>
          Selecting from the few ideas left, we soon arrived at the insight that in some way, the result needed to be a one-person sleeping pod. It soon became the Ulmer Nest, which was meant to be enriched by modern technologies to make it safe and integrated into existing processes.
        </p>
        <div class="title mb-3 mt-6">
          Mockup
        </div>
        <p>
          Having only a few hours left from the initial 2 days, we started to create a physical mockup out of wood, even including basic features of the connected IoT system that the Nest later became.
          To also be able to immerse into the future nests and get an idea of how it feels to stay in one, we created a VR experience in parallel.
          <br>
          Besides that, we also started to create a campaign to gain acceptance for the Nests amongst citizens, including the name - the "Ulmer Nests".
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>

<style>
  .video-container {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
</style>
