<template>
  <div>
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-10">
          Vielen Dank!
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Das Projekt Ulmer Nest wäre ohne Hilfe und Unterstützung nicht möglich. Daher möchten wir uns ganz herzlich bedanken:
        </p>
        <ul>
          <li>
            Ganz ausdrücklich bei Holger Hördt und Franziska Vogel von der Stadt Ulm, die das Projekt ins Rollen geracht haben und seither mit großem Engagement voran bringen.
          </li>
          <li>
            Beim Ulmer Gemeinderat für das Vertrauen und die Möglichkeit das Konzept Ulmer Nest erproben zu können.
          </li>
          <li>
            Bei der Generationenstiftung der Sparkasse Ulm für die finanzielle Unterstützung mit deren Hilfe die wissenschaftliche Begleitung mitfinanziert werden kann.
          </li>
          <li>
            Bei Nuki Home Solutions für die Bereitstellung von Equipment für unsere Prototypen.
          </li>
          <li>
            Bei Edeka Dörflinger und dem EU-Abgeordneten Norbert Links für den Erlös der "Kassieren für den guten Zweck"-Aktion zugunsten des Ulmer Nests.
          </li>
          <li>
            Bei bei der Digitale Agenda Ulm für die Berücksichtigung des Ulmer Nest für das Smart City Modellprojekt und damit die Möglichkeit die Prototypen in das Ulmer LoRaWanNetzwerk einzubinden.
          </li>
          <li>
            Bei der Firma Barz GmbH für die kostenlose Reinigung der Ulmer Nester 2021.
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'thanks',
  components: {
  }
}
</script>
