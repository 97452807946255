<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-4">
          Ursprung
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="title mb-3 mt-6">
          Wilhelmsbüro
        </div>
        <p>
          Das Ulmer Nest entsprang der Aktion “Wilhelmsbüro”. Diese fand im Herbst 2018 statt und war Teil der von der Stadt Ulm ausgeschriebenen Aktion “Stürmt die Burg” zur temporären Bespielung der Ulmer Wilhelmsburg - insbesondere der dort neu ausgebauten Räumlichkeiten.
          <br>
          Das Wilhelmsbüro besteht aus zwei jungen Ulmer Unternehmen (dem Design-Büro Bootschaft aus Neu-Ulm, der Software- und Hardware-Firma Widerstand und Söhne), sowie dem Ulmer Informatiker Florian Geiselhart.
          <br>
          Das insgesamt sechsköpfige Team hatte es sich zum Ziel gemacht die Burg als zukünftige Arbeitsstätte für kleine, innovative und vernetzte Unternehmen zu erproben und dabei in 5 wöchentlich aufeinander folgenden Sessions von jeweils zwei Tagen unterschiedlichste Aufgabenstellungen und Probleme von Bürger, Unternehmen und Institutionen zu lösen.
        </p>
        <div class="video-container">
          <iframe src="https://player.vimeo.com/video/296739281?byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
        <div class="title mb-3 mt-6">
          Aufgabenstellung
        </div>
        <p>
          In der Session Nummer drei kam die Aufgabe, die dem Team des Wilhelmsbüros bis zu Beginn der intensiven zweitägigen Workshop-Phase völlig unbekannt war, von der Stadt Ulm, genauer von der Abteilung für Soziales. Sie lautete, einen Erfrierungsschutz für die Stadt Ulm zu entwickeln.
        </p>
        <div class="title mb-3 mt-6">
          Herangehensweise
        </div>
        <p>
          Da das Team mit diesem Thema bis dahin nicht oder nur am Rande mit diesem Thema in Berührung gekommen ist, bestand die Vorgehensweise zunächst darin möglichst viele Informationen aus möglichst unterschiedlichen Perspektiven zusammenzutragen.
          <br>
          Gesprächspartner waren unter anderem Franziska Vogel als stellvertretende Aufgabenstellerin und Ideengeberin von der Abteilung für Soziales, Sabine Schwarzenböck von der Kulturabteilung als Projektpatin, Karin Ambacher vom DRK-Übernachtungsheim in der Frauenstraße, Rainer Türke vom Ordnungsamt Ulm, sowie Sascha Wagner von der mobilen Jugendarbeit Ulm.
        </p>
        <div class="title mb-3 mt-6">
          Erkenntnisse
        </div>
        <p>
          Die Ergebnisse der Gespräche wurden anschließend zusammengefasst und evaluiert, worauf hin sich zeigte, dass bereits einige Maßnahmen zum Erfrierungsschutz in Ulm bestehen, es jedoch auch zahlreiche Gründe gibt, aus denen betroffene Menschen diese Maßnahmen nicht in Anspruch nehmen können: Neben persönlichen Gründen, wie sich beispielsweise nicht mit vielen fremden Menschen in einem Raum aufhalten zu können, Angst vor Kriminalität und Gewalt, spielen hier auch Vorschriften und Regeln eine gewichtige Rolle: So können beispielsweise Hunde nicht mit in die Wohnheime genommen werden, alkoholisierte oder unter Drogen stehende Menschen werden in den Übernachtungsheimen in der Regel nicht aufgenommen, genauso wie nicht in Ulm gemeldete Menschen.
        </p>
        <p>
          Die anfangs bewusst sehr offene Herangehensweise bezüglich der Art und Weise des Ergebnisses - von Zwischennutzungsformen und Container-Siedlungen hin zu Online-Tools zur Vernetzung Hilfsbedürftiger und freiwilligen Helfern, wurde so im Lauf der intensiven Arbeit zielgerichtet immer weiter verschmälert.
        </p>
        <p>
          Letztendlich kristallisierte sich für das Wilhelmsbüro auf Grundlage der erhaltenen Informationen heraus, dass der Fokus auf diejenigen gelegt werden muss, die durch die Raster der existierenden Maßnahmen zum Erfrierungsschutz fallen und dennoch bei bedrohlich kalten Temperaturen nicht unter widrigen Bedingungen übernachten sollen müssen.
        </p>
        <p>
          Unter den erdachten Optionen trat eine bauliche Maßnahme in Form eines Ein-Personen-Schlafplatzes insbesondere hinsichtlich einer realistischen, zeitnahen Umsetzung hervor: Das Ulmer Nest - eine Schlafkapsel, die durch die Verwendung moderner Technologien zu einem sicheren und wartbaren Notfallinstrument werden soll.
        </p>
        <div class="title mb-3 mt-6">
          Mockup
        </div>
        <p>
          In den letzten Zügen der Session wurde ein erstes exemplarisches Mockup gebaut, um das Ulmer Nest anschaulich zu demonstrieren. Zur Verstärkung auch der emotionalen Situation wurde parallel ein Virtual Reality Setup entwickelt, dass es ermöglichte den physikalischen Prototypen in einer virtuellen dunklen Umgebung zu erfahren.
          <br>
          Zudem wurden Ansätze einer akzeptanzsteigernden Kampagne zur Einführung der Maßnahme in der Bürgerschaft entwickelt, wobei auch der Name "Ulmer Nest" entstand.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>

<style>
  .video-container {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
</style>
