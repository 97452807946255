<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-10">
          Welcome!
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Thank you for your interest in this project! The Ulmer Nest is a pilot study to learn about new ways of providing support in hypothermia death prevention in homeless people.
        </p>
        <p>
          The project originated from a proposal of the municipality of Ulm, Germany, within the temporary problem-solving initiative "Wilhelmsbüro" of the two Ulm-based start-up companies Bootschaft and Widerstand und Söhne, and the computer scientist Florian Geiselhart. This project team, consisting of Product- and Interface Designers as well as Hardware and Software Developers initiated the Ulmer Nest project, and developed it to its current state.
        </p>
        <p>
          On this website, you can find comprehensive information on the process, concept and first results from the field testing of the Ulmer Nest. If you have any further questions, feel free to contact us.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
