<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-4">
          Kontakt
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Sollten Sie Fragen rund um das Ulmer Nest haben, können Sie sich gerne über nachfolgende E-Mail-Adresse an uns wenden. Wir werden Ihre Anfrage schnellstmöglich bearbeiten:
        </p>
        <p>
          <a href="mailto:info@ulmernest.de">
            <div class="font-weight-bold mb-3 mt-3">
              <v-icon small class="mr-3 mb-1">
                fa fa-envelope
              </v-icon>
              info@ulmernest.de
            </div>
          </a>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-4">
          Presse
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Die auf diesen Seite zur Verfügung gestellten Informationen finden Sie in einem PDF zusammengefasst, gemeinsam mit Bildmaterial in nachfolgendem Download:
        </p>
        <p>
          <a href="https://ulmernest.de/downloads/presse_191107_V1.zip" target="_blank">
            <div class="font-weight-bold">
              <v-icon small class="mr-3 mb-1">
                fa fa-cloud-download-alt
              </v-icon>
              Pressekit 2020 (PDF + Bildmaterial)
            </div>
          </a>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Bildmaterial zur freien Verfügung unter Angabe der entsprechenden Urheber (siehe dazu .rtf-Dateien im jeweiligen Ordner):
        </p>
        <p>
          <a href="https://ulmernest.de/downloads/ulmernest_media_210303_V1.zip" target="_blank">
            <div class="font-weight-bold">
              <v-icon small class="mr-3 mb-1">
                fa fa-cloud-download-alt
              </v-icon>
              Bildmaterial 2020 + 2021
            </div>
          </a>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Für weitere Fragen oder die Zusendung von Belegexemplaren nutzen Sie bitte nachfolgende Kontaktmöglichkeiten:
        </p>
        <p>
          <a href="mailto:presse@ulmernest.de">
            <div class="font-weight-bold">
              <v-icon small class="mr-3 mb-1">
                fa fa-envelope
              </v-icon>
              presse@ulmernest.de
            </div>
          </a>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
