<template>
  <component :is="computedComponent"></component>
</template>

<script>

import Ursprung from '@/views/Ursprung'
import UrsprungEn from '@/views/Ursprung_en'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    Ursprung,
    UrsprungEn
  },
  computed: {
    ...mapGetters([
      'language'
    ]),
    computedComponent () {
      if (this.language === 'de') {
        return 'Ursprung'
      } else {
        return 'UrsprungEn'
      }
    }
  }
}
</script>
