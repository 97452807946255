<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-4">
          Development
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="title mb-3 mt-6">
          Form prototype and Moodboard
        </div>
        <p>
          Starting with the mockup resulting from the Wilhelmsbüro session, and results from a round table discussion with the city of Ulm, which was held with a diverse set of stakeholders regarding homelessness, a prototype has been developed, which is closer to the actual end result and a future Ulmer Nest. This prototype is considered to be a form prototype, whose purpose is to visualize the construction-related aspects of the Nest and to make the actual overall impression and proportions tangible.
        </p>
        <p>
          The form prototype was presented at two differenct locations to the public as well as people working in aid for the homeless specifially, to capture their respective impressions and opinions in semi-structured interviews.
        </p>
        <div class="title mb-3 mt-6">
          Planning
        </div>
        <p>
          The following steps consisted of discussions with representatives of the fire brigade, police, citizen services, medical staff, cleaning staff, EBU (the Ulm city waste management department) as well as possible future partners in manufacturing the Ulmer Nests. Topics that were raised within this discussions amongst other consisted of construction, maintenance and cleaning, possible locations for Nests as well as future processes and information flow (e.g. which status messages should be submitted to whom).
        </p>
        <div class="title mb-3 mt-6">
          Choice of Material
        </div>
        <p>
          Choosing the right material is a challenging task, and involves careful consideration of different aspects. After having talked to experts from different areas, for the field testing prototype the  material combination of massive wood for the Nest body, and power-coated metal sheets for cleaning-intensive and hygienically critical parts was chosen to be suitable.
        </p>
        <p>
          After assembling the elements, the wood is coated with a stain to make it resistant to the expected weather conditions. As a renewable, easy-to-work-with material, wood is the best choice considering economical and ecological aspects, but also when it comes to its durability and insulation properties.
        </p>
        <p>
          In future iterations, it is to be considered whether parts of the Ulmer Nest could also be manufactured using a rotational moulding process using plastic, or also formed components from glass fibre or carbon composites, but at the current state not yet reasonable. One reason for this is the high initial cost for mould design and construction, which is only refinancable through larger numbers of Nests. Also, the worse ecological footprint has to be considered when compared to the additional benefit.
        </p>
        <div class="title mb-3 mt-6">
          Technical components and software
        </div>
        <p>
          The technical side of the Ulmer Nest was already considered an important part during the initial research phase on hypothermia death prevention at the pop up space: To monitor the user's safety, but also the side effects of providing such a shelter like waste and pollution with minimal effort, a concept was developed which builds on using sensor technology and an easy-to-use, self-contained communication platform for the involved stakeholders.
        </p>
        <p>
          Using the revised functional concept of the Nest, the necessary hardware was carefully chosen: Sensors for GPS, temperature and humidity, smoke and CO2, motion detection in the interior space, electronic monitoring of the door lock state together with a basic illumination, an acoustic alarm signal and a heat exchanger and ventilation system all connect to a central control unit.
        </p>
        <p>
          The supply of fresh air is accomplished through a heat exchanger with a ceramic element for heat recovery, which means that despite continuous air exchange the indoor temperature can be retained, or it can even increase.
        </p>
        <div class="title mb-3 mt-6">
          Software and Data
        </div>
        <p>
          The collected sensor values are being tracked, saved and (depending on available infrastructure) transmitted via LoRaWAN or GSM to a server, where they are written to a database together with a timestamp.
        </p>
        <p>
          The collected data are not only being used to guarantee a continuous operation and monitor availability of the Nests through a private web platform, but are also valuable for evaluation of the overall concept. LoRaWAN is thus substantially contributing to an acquisition of previously mostly grey areas of urban processes.
        </p>
        <p>
          To make the data of active Nests available and accessible in a user-friendly way, wireframes were developed which formed the base for a web application for the field testing phase.
        </p>
        <div class="title mb-3 mt-6">
          Safety
        </div>
        <p>
          For the field testing, together with the fire department requirements on preventive fire protection of the material and the system were defined.
        </p>
        <p>
          During prior discussions with the public and homeless people, safety of potential users was always considered to be a very important factor. This led to a choice of materials which are designed to withstand possible safety-affecting factors from the outside as well as from the inside as well as possible. The most important factors were stability and fire protection, combined with a secure locking system.
        </p>
        <p>
          It is still to be learned during the field testing if and how the Ulmer Nest will be affected by vandalism, while damages can be only fixed through cleaning or repairs during this phase.
        </p>
        <div class="title mb-3 mt-6">
          Cleaning
        </div>
        <p>
          A fast and straightforward cleaning process is important both for the hygienical situation in the Nests and the running costs in productive setups. With this in mind, the Nest was designed for a fast and hygienical cleaning using professional cleaning equipment.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
