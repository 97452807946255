<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-4">
          Impressum
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <b>Widerstand und Söhne GmbH</b>
        <br>Prittwitzstr. 100
        <br>89075 Ulm
        <br>
        <br>Registergericht: Amtsgericht Ulm
        <br>Handelsregisternummer: HRB 734906
        <br>Umsatzsteuer-ID: DE311314354
        <br>
        <br><b>Geschäftsführer</b>
        <br>Dirk Bayer
        <br>Falko Pross
        <br>Manuel Schall
        <br>
        <br><b>Verantwortlich für den Inhalt</b>
        <br>Dirk Bayer
        <br>Falko Pross
        <br>Manuel Schall
        <br>
        <br><b>Kontakt</b>
        <br><a href="mailto:info@ulmernest.de">info@ulmernest.de</a>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
