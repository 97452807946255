<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-4">
          Entwicklung
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="title mb-3 mt-6">
          Formprototyp und Stimmungsbild
        </div>
        <p>
          Ausgehend von dem Mockup der Wilhelmsbüro-Session und Ergebnissen eines Gesprächs am “runden Tisch” der Stadt Ulm, bei dem verschiedene Akteure zum Thema Obdachlosigkeit in Ulm vertreten waren, wurde ein Prototyp entwickelt, der näher an dem tatsächlichen Endergebnis und einem späteren Ulmer Nest sein sollte. Dieser Prototyp versteht sich als Formprototyp, der die baulichen Aspekte des Ulmer Nests visualisieren und zur Erprobung von Wirkung und Maßstäben dienen soll.
        </p>
        <p>
          Der Formprototyp wurde an zwei unterschiedlichen Orten sowohl Passanten, als auch betroffenen und in der Wohnungslosenhilfe tätigen Personen vorgestellt, deren grundsätzliche Eindrücke und Meinungen damit aufgefangen werden sollten.
          <br>
          Dieses Einfangen von Meinungen fand mit einem Gesprächsleitfaden, in freien Unterhaltungen statt.
        </p>
        <div class="title mb-3 mt-6">
          Planung
        </div>
        <p>
          Die folgenden Schritte bestanden darin, Gespräche mit Vertretern von Feuerwehr, Polizei, Bürgerdiensten, medizinischem Personal, Reinigungsfirmen, EBU, sowie möglichen Partnern für die zukünftige Produktion des Ulmer Nests zu führen. Dabei sind der Umgang mit dem Ulmer Nests von baulicher Seite, die Handhabung bezüglich Wartung und Reinigung, die Standortwahl, sowie die zu definierende Informationskette, insbesondere an wen welche Statusmeldungen übermittelt werden sollen, diskutiert worden.
        </p>
        <div class="title mb-3 mt-6">
          Materialwahl
        </div>
        <p>
          Die Materialwahl ist eine anspruchsvolle Aufgabe, bei der verschiedenste Aspekte berücksichtigt werden mussten. Nach Gesprächen mit Experten aus unterschiedlichen Bereichen wurde für die Prototypen der Echterprobung, eine Materialkombination aus massivem Holz für den Grundkörper und pulverbeschichteten Stahlblechteilen für die besonders reinigungsintensiven und hygienisch kritischsten Teile, als sinnvoll erachtet.
        </p>
        <p>
          Im Anschluss an die Montage der einzelnen Elemente wird das Holz oberflächlich mit einer Lasur versiegelt und hält somit den zu erwartenden Witterungsbedingungen stand. Holz als nachwachsender Rohstoff, der relativ kostengünstig zu verarbeitenden ist, gute thermische Isolationseigenschaften besitzt und in dieser Stärke durchaus beständig ist, ist für diese Zwecke die ökonomisch und ökologisch beste Wahl.
        </p>
        <p>
          Dass im Zuge einer zukünftigen Weiterentwicklung des Ulmer Nests auch Teile im Rotationsgussverfahren aus Kunststoff oder Formteile aus Glas- oder Kohlefaserverbundstoffen hergestellt werden, ist generell denkbar, aber zu diesem Zeitpunkt nicht sinnvoll. Ein Grund dafür sind die hohen Initialkosten für den benötigten Formenbau, der sich nur durch eine große Anzahl produzierter Exemplare refinanzieren lassen würde. Ebenfalls müsste die deutlich höhere, damit einhergehende Umweltbelastung eventuellen Mehrwerten gegenübergestellt werden.
        </p>
        <div class="title mb-3 mt-6">
          Technische Komponenten und Software
        </div>
        <p>
          Die technische Komponente des Ulmer Nest stand bereits bei den ersten Überlegungen zum Thema Erfrierungsschutz während des Pop Up Spaces stark im Fokus: Um die Sicherheit der Nutzer, aber auch alle Begleiterscheinungen einer Übernachtungsstätte, wie Müll und Verunreinigungen bestmöglich zu kontrollieren, gleichzeitig aber den dafür zu leistenden Aufwand minimal zu halten, wurde ein Konzept entwickelt, das auf dem Einsatz von Sensoren und einer einfach zu bedienenden, geschlossenen Kommunikations-Plattform zur Verständigung beteiligter Akteure basiert.
        </p>
        <p>
          Gemäß des überarbeiteten Konzeptes zur Funktionalität des Ulmer Nests wurde die benötigte Hardware zusammengestellt: Sensoren für GPS, Temperatur und Luftfeuchtigkeit, Rauch und CO2, Bewegungserfassung im Innenraum, der elektronischen Überprüfung der Verriegelung von Innen, laufen gemeinsam mit einer Grundbeleuchtung, einem Alarm-Signal-Summer und einem Wärmetauscher, bzw. Belüftungssystem in einer zentralen Steuereinheit zusammen.
        </p>
        <p>
          Die Versorgung mit Frischluft wird über einen Wärmetauscher mit einem Keramikelement zur Wärmerückgewinnung gewährleistet, sodass trotz des Austauschs von Raum- und Frischluft die Innentemperatur weitestgehend erhalten bleibt, bzw. diese weiter steigen kann.
        </p>
        <div class="title mb-3 mt-6">
          Software und Daten
        </div>
        <p>
          Die gesammelten Sensorwerte werden über die zentrale Steuereinheit erfasst, gespeichert und abhängig von der Verfügbarkeit via LoraWAN oder GSM an einen Datenserver übertragen, wo diese Werte mit einem Zeitstempel in eine Datenbank geschrieben werden.
        </p>
        <p>
          Die Daten sorgen nicht nur für den reibungslosen Betrieb und die Verfügbarkeit des aktuellen Stands der Ulmer Nester über die geschlossene Webplattform, sondern ist auch für die Evaluation des Konzepts des sehr wertvoll. Lorawan trägt hier maßgeblich zur Digitalisierung und Erfassung  eines bisher weitgehendst unerfassten Bereichs städtischen Lebens bei.
        </p>
        <p>
          Für eine benutzerfreundliche Darstellung der Daten aktiver Ulmer Nester, der Weitergabe dieser und weiterer Informationen wurden Wireframes entwickelt, auf deren Grundlage für eine Echterprobung eine Web-Applikation entwickelt wird.
        </p>
        <div class="title mb-3 mt-6">
          Sicherheit
        </div>
        <p>
          Für eine Echterprobung des Ulmer Nests war eine Abstimmung mit der Feuerwehr bezüglich des einzuhaltenden Brandschutzes nötig, bei der die Anforderungen an das Material definiert werden konnten.
        </p>
        <p>
          In den vorausgehenden Gesprächen sowohl mit Passanten, wie auch mit Betroffenen ist das Thema Sicherheit für die Nutzer stets mit einem sehr hohen Stellenwert belegt worden. So sind die Materialien des Ulmer Nests hinsichtlich der Sicherheit durch Einflüsse von Außen, wie auch von Innen auf die bestmöglichen Eigenschaften hin ausgewählt. Die wichtigsten Faktoren sind hierbei Stabilität und Brandschutz in Kombination mit einem sicheren Schließsystem.
        </p>
        <p>
          Ob und in welchem Umfang das Ulmer Nest von Vandalismus betroffen sein wird, muss in der Echterprobung evaluiert werden, in der lediglich Wiederherstellungsmaßnahmen durch Reinigung oder Reparatur durchgeführt werden können.
        </p>
        <div class="title mb-3 mt-6">
          Reinigung
        </div>
        <p>
          Eine schnelle und unkomplizierte Reinigung spielt bezüglich der hygienischen Zustände im Inneren eines Ulmer Nests, aber auch bezüglich der laufenden Kosten im Produktivbetrieb eine wichtige Rolle. Daher wurde das Ulmer Nest für eine schnell durchzuführende, hygienische Reinigung mit professionellen Reinigungsgeräten optimiert.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
