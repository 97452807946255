<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-4">
          Team
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Das Ulmer Nest wird von einem interdisziplinären Team entwickelt, dass sich aus den Gründern zweier junger Ulmer Unternehmen, sowie dem Informatiker Florian Geiselhart zusammensetzt:
        </p>
        <v-img class="my-6" src="/img/team.jpg"></v-img>
        <p>Von links nach rechts:</p>
        <p>
          <b>Patrick Kaczmarek</b> Produkt-Design<br>
          <b>Florian Geiselhart</b> Software-Entwicklung<br>
          <b>Falko Pross</b> Web-Entwicklung<br>
          <b>Manuel Schall</b> Software-Entwicklung<br>
          <b>Dirk Bayer</b> Hardware-Entwicklung<br>
          <b>Kathrin Uhlig</b> User-Interface- und User-Experience-Design<br>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <a href="https://bootschaft.de" target="_blank">
          <div class="title mb-3 mt-6">
            Bootschaft
            <v-icon small class="ml-1 mb-1">
              fa fa-external-link-alt
            </v-icon>
          </div>
        </a>
        <p>
          Seit der Gründung im Jahre 2013 in Neu-Ulm, bedient, berät und betreut die Bootschaft Kunden aus den unterschiedlichsten Branchen bei Themen rund um Design.
        </p>
        <p>
          Die interdisziplinäre Zusammensetzung des Kernteams, in Kombination mit einem soliden und breit gefächerten Netzwerk – das aus einer Vielzahl an zuverlässigen und kompetenten Partnern besteht – ermöglicht die Zusammenstellung optimaler Teams für jedes individuelle Projekt. Die Arbeitsweise ist lösungsorientiert und zeichnet sich durch systematisches und analytisches Vorgehen aus, während der Fokus auf der Entwicklung von starken und schlüssigen Konzepten liegt.
        </p>
        <p>
          Die Kernkompetenz der Bootschaft liegt dort, wo die Gestaltung von Produkten und dem dazugehörigen User Interface bzw. der User Experience (UX/UI) gefragt sind.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <a href="https://widerstandundsoehne.de" target="_blank">
          <div class="title mb-6 mt-6">
            Widerstand und Söhne
            <v-icon small class="ml-1 mb-1">
              fa fa-external-link-alt
            </v-icon>
          </div>
        </a>
        <p>
          Widerstand und Söhne wurde 2017 in Ulm gegründet und besteht aus einem interdisziplinären Team von Informatikern aus Automatisierung, Hardwareentwicklung, Softwareentwicklung und Usability-Engineering.
        </p>
        <p>
          Das Unternehmen entwickelt individuelle, vernetzte Hardware- und Softwarelösungen für Industrie, Mittelstand, Kunst und Kultur. Dabei werden die Kunden ganzumfänglich von der Konzeption bis hin zum fertigen Produkt betreut.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <a href="https://florian-geiselhart.de" target="_blank">
          <div class="title mb-6 mt-6">
            Florian Geiselhart
            <v-icon small class="ml-1 mb-1">
              fa fa-external-link-alt
            </v-icon>
          </div>
          <p>
            Florian Geiselhart berät und entwickelt als freiberuflicher Medieninformatiker im Bereich von interaktiven digitalen Lösungen und Konzepten, die meist im Spannungsfeld zwischen nutzerzentrierter Gestaltung, Technologie und Kunst angesiedelt sind. Derzeit lehrt er im Rahmen einer Vertretungsprofessur an der Hochschule für Gestaltung Schwäbisch Gmünd im Bereich "Gestaltung vernetzter Systeme".
          </p>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
