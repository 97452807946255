<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-4">
          Häufig gestellte Fragen (FAQ)
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="title mb-3 mt-6">
          Wie viel kostet ein Ulmer Nest? Wie kann ich eines erwerben?
        </div>
        <p>
          Momentan existieren lediglich zwei händisch zusammengebaute Prototypen um das Gesamtkonzept des Projekts Ulmer Nests zu evaluieren.
          Zum jetzigen Zeitpunkt sind wir leider nicht in der Lage weitere Ulmer Nester herzustellen und zu verkaufen. Auch ein möglicher späterer Herstellungspreis ist noch nicht absehbar, da dieser stark von Faktoren wie z.B. der zukünftigen Entwicklung und den Stücktahlen abhängig sein wird.
          Wir sind dabei die Möglichkeiten einer Optimierung der Herstellung hinsichtlich der Materialien, Handhabung und Produktionsabläufen hin zu einer Art Serienproduktion zu evaluieren.
        </p>
        <p>
          Wenn Sie einer städtischen Verwaltung, einer NGO oder einem Unternehmen angehören und daran interessiert sind das Ulmer Nest, oder eine vergleichbare Lösung, bei Ihnen zum Einsatz zu bringen, stehen wir gerne jederzeit für einen Austausch zur Verfügung und halten Sie auf Wunsch auf dem aktuellen Stand der Entwicklung.
        </p>
        <p>
          Bitte beachten Sie auch, dass neben den inititlen Anschaffungskosten eines Ulmer Nests auch weitere Kosten für den laufenden Betrieb anfallen um das Konzept des Ulmer Nests vollständig umsetzen zu können. Hierunter fallen beispielsweise Kosten für die begleitende Sozialarbeit, Reinigung oder Wartung / Reparatur.
          Das Ulmer Nest ist ein Konzept, dass nur bei Beachtung und Umsetzung aller Abläufe und Mechanismen funktioniert.
        </p>

        <div class="title mb-3 mt-6">
          Gibt es Konstruktionspläne oder Anleitungen um ein Ulmer Nest selbst bauen zu können?
        </div>
        <p>
          Derzeit sind keine Selbstbau-Instruktionen oder Pläne verfügbar, da sich die aktuellen Prototypen noch in der Evaluation befinden und praktisch täglich überarbeitet werden, so dass veröffentlichte Pläne entsprechend oft überabreitet werden müssten. Dies können wir derzeit leider nicht abbilden.
        </p>
        <p>
          Für den jetzigen Zeitpunkt möchten wir Sie ermutigen Ihre eigenen Ideen zu verfolgen, wenn vorhanden.
          Wir stehen, soweit es unsere Möglichkeiten zulassen, gerne mit Erfahrungswerten zur Seite.
        </p>
        <div class="title mb-3 mt-6">
          Ich würde gerne in das Ulmer Nest investieren / an der weiteren Entstehung mitwirken.
        </div>
        <p>
          Wenn Sie über Kompentenzen in für das Ulmer Nest möglicherweise relevanten Bereichen verfügen und Interesse am Projekt haben, oder über Investitionen in eine nicht rein profitorientierte Sache nachdenken und uns unterstützen möchten, kontaktieren Sie uns gerne via business@ulmernest.de
        </p>
        <div class="title mb-3 mt-6">
          Wie setzen sich die in den Medien genannten Kosten für das Ulmer Nest zusammen?
        </div>
        <p>
          Das Ulmer Nest befindet sich momentan in einer frühen Erprobungsphase. Der momentane “Preis” pro Nest ist daher sehr viel höher als er bei einem fertigen Produkt ausfallen würde, da noch sehr viel Entwicklungsarbeit stattfindet, und jedes Nest einzeln von Hand zusammengebaut wird. Für einen späteren Serieneinsatz wird der Preis deutlich niedriger ausfallen.
        </p>
        <p>
          In den Kosten ist nicht nur das Material enthalten, sondern auch Entwicklung, Produktion, Sensorik, Programmierung, Aufbau und Abbau, Transport, Betreuung, Wartung und Reinigung über die Wintermonate. Kurz: die gesamte Umsetzung des Projektes für die momentan stattfindende Prototypenphase.
        </p>
        <div class="title mb-3 mt-6">
          Wer ist das Team hinter dem Ulmer Nest?
        </div>
        <p>
          Entgegen Medienberichten handelt es sich beim Ulmer Nest um kein “Kunstprojekt”, sondern um das Ergebnis einer Kollaboration von sechs Ulmer Unternehmern mit breiter technischer Expertise in der Gestaltung und Entwicklung von Produkten. Dadurch sind in allen Bereichen der Entwicklung Kompetenzen vorhanden, von Materialwahl über Elektronik bis hin zu Software. Wo eigene Kompetenzen nicht ausreichen, wird durch die Expertise eines lokalen Firmennetzwerks ergänzt.
        </p>
        <div class="title mb-3 mt-6">
          Warum wird das Geld nicht lieber zur Unterstützung bestehender Hilfseinrichtungen für Obdachlose genutzt?
        </div>
        <p>
          Das Ulmer Nest ist eine Maßnahme zur Unterstützung einer speziellen Zielgruppe - nämlich derer die aus verschiedenen Gründen nicht in regulären Obdachlosenunterkünften unterkommen können. Dies kann aufgrund psychischer / physischer Faktoren der Fall sein, aber auch weil kein Wohnsitz in Ulm besteht, oder der vierbeinige Begleiter nicht mit in die Unterkunft genommen werden darf. Ohne eine Maßnahme wie das Ulmer Nest ist diese Gruppe von Personen gänzlich ungeschützt, und muss unter Lebensgefahr auch in den kältesten Nächten des Jahres ungeschützt im Freien übernachten.
        </p>
        <div class="title mb-3 mt-6">
          Warum wird das Geld nicht lieber zur Erschaffung bezahlbaren Wohnraums genutzt?
        </div>
        <p>
          Das Ulmer Nest stellt keine Alternative zu Wohnraum dar, auch ist es nicht als Wohnraum konzipiert. Es ist eine reine Notschlafgelegenheit, die in besonders kalten Nächten vor gesundheitlichen Schäden oder Tod durch Erfrierungen schützen soll - ähnlich einer Wetterschutzhütte.
        </p>
        <div class="title mb-3 mt-6">
          Wie wird sichergestellt dass das Nest auch wirklich von den Betroffenen genutzt werden kann, und nicht durch andere Personen blockiert wird?
        </div>
        <p>
          Die Nutzung durch andere Personen außerhalb des Betroffenenkreises lässt sich nicht gänzlich ausschließen. Mit geeigneten Maßnahmen wie regelmäßiger Kontrolle, Entriegelung erst bei entsprechend niedrigen Temperaturen etc. wird dies jedoch so gut wie möglich eingedämmt. Letztendlich sind wir aber der Meinung, dass im Zweifel eine gelegentliche “Fremdnutzung” tolerierbar ist, wenn dadurch ein niederschwelliges Hilfsangebot geschaffen werden kann welches sonst nicht existent wäre.
        </p>
        <div class="title mb-3 mt-6">
          Sollten die Betroffenen nicht eher durch geeignete soziale Maßnahmen in feste Wohnungen gebracht werden, anstatt das Leben auf der Straße durch so etwas wie das Nest zu unterstützen?
        </div>
        <p>
          Ja, das Ziel muss immer sein, die Betroffenen zu unterstützen, langfristig zu einem Leben in einer angemessenen Wohnsituation zu finden und dieses aufrechterhalten zu können. Das Nest als “Sofortmaßnahme” zielt hier aber einerseits auf Personen ab, bei denen dieser Prozess noch nicht weit genug fortgeschritten ist, andererseits bietet es aber auch einen zusätzliche “Touchpoint”, den Streetworker und Sozialarbeiter nutzen können um mit den Betroffenen in Kontakt und ins Gespräch kommen zu können.
        </p>
        <div class="title mb-3 mt-6">
          Garantiert das Ulmer Nest das Überleben unter allen Bedingungen?
        </div>
        <p>
          Nein, auch in einem Ulmer Nest können lebensbedrohlich niedrige Temperaturen auftreten. Das Ulmer Nest minimiert durch seine geschlossene, isolierte Bauform negative Umgebungseinflüsse wie Wind und Nässe. Kälte kann mittels der eigenen Körperwärme der Nutzer auf engem Raum etwas nach oben korrigiert werden. Es ist somit als eine Alternative zu einer Übernachtung im Freien und in keinster Weise als eine Alternative zu einer Übernachtung in einer kontrollierten Umgebung wie einem Übernachtungsheim zu betrachten. Das Ulmer Nest ist ein Notfallinstrument in letzter Instanz.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
