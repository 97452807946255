<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-10">
          Goals
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Ulmer Nest is an attempt to supplement existing measures in hypothermia death prevention.
          It is a sleeping pod functioning as an emergency shelter to protect homeless people in extremely cold nights from coldness, wind and moisture.
          <br>
          It is targeted at those who can not accept existing measures like collective accomodations and therefore is an alternative to a unprotected night outdoors.
          <br>
          Ulmer Nest is not an alterative to spending the night in a controlled and secure environment like an homeless shelter.
          The closed and isolated construction reduces and minimizes wind, moisture, coldness and other negative influences.
          Nevertheless it can become dangerously cold inside.
        </p>
        <p>
          This pilot study provides numerous opportunities for the people concerned as well as for the city of Ulm.
        </p>
        <!-- TODO -->
        <p>It creates the opportunity of evaluating whether the Ulmer Nest is suitable as a means of protecting people from freezing to death. The city of Ulm acts as an outstanding innovator taking the first steps, not only regarding the technical aspects like the sustained use of modern IoT technology like the citywide LoRaWan network for digitizing the processes around the Nests, but also in its pursue to find answers to challenges of nowadays problems in urban living environments.
        </p>
        <p>
          If the Ulmer Nests turn out to be an adequate means for frostbite protection, especially for people who cannot accept other existing measures for a wealth of different reasons, it can be presented in a larger context with the goal of a more broad deployment. Otherwise, the results could act as an important source of knowledge about means to live up to the social responsibility cities do have.
        </p>
        <p>
          In both cases, the evaluation will foster precious insights about the effects of deploying mobile emergeny shelters against frostbite regarding their users, but also their enviroment and citizens in their perimeter. Those insights might also be generalized towards their overall effects on city and citizens when implementing publicly visible measures against homelessness and freezing deaths. This in turn can be used in the further development of alternative solutions or overall strategies.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
