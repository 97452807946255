<template>
  <component :is="computedComponent"></component>
</template>

<script>

import Impressum from '@/views/Impressum'
import ImpressumEn from '@/views/Impressum_en'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    Impressum,
    ImpressumEn
  },
  computed: {
    ...mapGetters([
      'language'
    ]),
    computedComponent () {
      if (this.language === 'de') {
        return 'Impressum'
      } else {
        return 'ImpressumEn'
      }
    }
  }
}
</script>
