<template>
  <component :is="computedComponent"></component>
</template>

<script>

import Presse from '@/views/Presse'
import PresseEn from '@/views/Presse_en'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    Presse,
    PresseEn
  },
  computed: {
    ...mapGetters([
      'language'
    ]),
    computedComponent () {
      if (this.language === 'de') {
        return 'Presse'
      } else {
        return 'PresseEn'
      }
    }
  }
}
</script>
