<template>
  <component :is="computedComponent"></component>
</template>

<script>

import Team from '@/views/Team'
import TeamEn from '@/views/Team_en'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    Team,
    TeamEn
  },
  computed: {
    ...mapGetters([
      'language'
    ]),
    computedComponent () {
      if (this.language === 'de') {
        return 'Team'
      } else {
        return 'TeamEn'
      }
    }
  }
}
</script>
