<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-10">
          Ziele
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Das Ulmer Nest ist der Versuch einer Ergänzung zum bestehenden Erfrierungsschutz in der Stadt Ulm. Es ist eine Notzuflucht in Form einer Schlafkapsel, die obdachlose Menschen in besonders kalten Nächten vor lebensbedrohlichen Temperaturen, Wind und Nässe schützen soll.
          <br>
          Insbesondere sollen hier diejenigen aufgefangen werden, denen es aus einer Vielzahl an Gründen nicht möglich ist, die bestehenden Maßnahmen wie Sammelunterkünfte oder Übernachtungsheime zu nutzen. Das Ulmer Nest bietet für diese durch alle Raster gefallenen Menschen als Notfallinstrument in letzter Instanz einen niederschwelligen Schutz für Leib und Leben und gilt als Alternative zu einer ungeschützten Übernachtung im Freien.
          <br>
          Das Ulmer Nest ist keine Alternative zu einer Übernachtung in einem Übernachtungsheim oder einer ähnlich gut kontrollierten Umgebung. Es ist eine Alternative zu einer Übernachtung unter freiem Himmel. Hier sollen durch die geschlossene, isolierte Bauform die negativen Umgebungseinflüsse wie Wind, Nässe und Kälte minimiert, bzw. reduziert werden. Dennoch können auch in einem Ulmer Nest lebensbedrohlich niedrigen Temperaturen auftreten. Es ist daher, wie oben bereits geschrieben explizit als allerletzte Option zu betrachten.
        </p>
        <p>
          Die Erprobung des Konzepts des Ulmer Nest bietet eine Vielzahl an Chancen sowohl für die Betroffenen, wie auch für die Stadt Ulm.
        </p>
        <p>
          Es kann hier in einem bundesweit einmaligen Projekt evaluiert werden, ob das Ulmer Nest als Maßnahme zum Erfrierungsschutz geeignet ist. Die Stadt Ulm tritt hier in einer beachtenswerten Vorbildfunktion als Vorreiter und Ideengeber auf - sowohl in Bezug auf die technischen Aspekte, wie der Nutzung des stadtweiten LoraWans zur Digitalisierung der Abläufe rund um das Ulmer Nest, als auch in den Bestrebungen Antworten auf die Herausforderungen des modernen städtischen Zusammenlebens zu finden.
        </p>
        <p>
          Sollten sich die Ulmer Nester als geeignetes Mittel zum Erfrierungsschutz, insbesondere für diejenigen Menschen, die bestehende Maßnahmen aus unterschiedlichsten Gründen ablehnen (siehe Absatz Ausgangslage), bewahrheiten, kann die Lösung überregional präsentiert und eingesetzt werden. Andernfalls konnte eine wichtige Frage der städtischen sozialen Verantwortung untersucht und begründet beantwortet werden.
        </p>
        <p>
          In beiden Fällen werden durch die Evaluation wichtige Erkenntnisse über die Auswirkungen mobiler Notfall-Schlafkapseln zum Erfrierungsschutz auf die Betroffenen, aber auch die Umgebung und Anwohner gewonnen. Diese Erkenntnisse können auch allgemein auf den Einsatz öffentlichkeitswirksamer Maßnahmen zum Thema Obdachlosigkeit und Erfrierungsschutz und deren Auswirkungen auf die städtische Gesellschaft abgeleitet und in weiteren Überlegungen und Bestrebungen eingesetzt werden.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
