'<template>

<v-app>

  <v-app-bar
    app
    flat
    fixed
    prominent
    shrink-on-scroll
    src="/img/bg.jpg"
  >
    <template v-slot:extension>
      <v-row>
        <v-col>
          <img src="/img/logo.svg" height="60px" class="mb-12">
          <v-spacer></v-spacer>
          <v-btn
            v-if="language === 'de'"
            absolute
            bottom
            right
            tile
            color="rgba(0,0,0,0.3)"
            class="mb-3"
            style="margin-right: 80px;"
            @click="setLanguage('en')"
          >
            <span class="font-weight-bold mt-1 white--text">
              ENGLISH
            </span>
          </v-btn>
          <v-btn
            v-else
            absolute
            bottom
            right
            tile
            color="rgba(0,0,0,0.3)"
            class="mb-3"
            style="margin-right: 80px;"
            @click="setLanguage('de')"
          >
            <span class="font-weight-bold mt-1 white--text">
              DEUTSCH
            </span>
          </v-btn>
          <v-btn
            absolute
            bottom
            right
            color="rgba(0,0,0,0.3)"
            large
            fab
            class="mb-12"
            @click="showNav = !showNav"
          >
            <v-icon
              color="white"
            >
              fas fa-bars
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-app-bar>

  <v-navigation-drawer
    app
    right
    v-model="showNav"
  >
    <v-list-item @click="$router.push('/')">
      <v-list-item-content>
        <v-list-item-title class="title">
          Ulmer Nest
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ language === 'de' ? 'Startseite' : 'Home' }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn @click="showNav = !showNav" icon>
          <v-icon color="grey lighten-1">fa fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-list
      nav
    >
    <v-divider></v-divider>
    <v-list-item class="mb-0" @click="$router.push('/ziele')">
      <v-list-item-content>
        <v-list-item-title class="">
          {{ language === 'de' ? 'Ziele' : 'Goals' }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item class="mb-0" @click="$router.push('/ursprung')">
      <v-list-item-content>
        <v-list-item-title class="">
          {{ language === 'de' ? 'Ursprung' : 'Idea' }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item class="mb-0" @click="$router.push('/entwicklung')">
      <v-list-item-content>
        <v-list-item-title class="">
          {{ language === 'de' ? 'Entwicklung' : 'Development' }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item class="mb-0" @click="$router.push('/team')">
      <v-list-item-content>
        <v-list-item-title class="">
          Team
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item class="mb-0" @click="$router.push('/faq')">
      <v-list-item-content>
        <v-list-item-title class="">
          {{ language === 'de' ? 'Häufig gestellte Frage (FAQ)' : 'FAQ' }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item class="mb-0" @click="$router.push('/danke')">
      <v-list-item-content>
        <v-list-item-title class="">
          {{ language === 'de' ? 'Danke' : 'Thanks' }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item class="mb-0" @click="$router.push('/kontakt')">
      <v-list-item-content>
        <v-list-item-title class="">
          {{ language === 'de' ? 'Kontakt & Presse' : 'Contact & Press' }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-footer
      absolute
      bottom
      color="transparent"
    >
     <span
      @click="$router.push('/impressum')"
      class="caption mr-1"
      style="cursor:pointer"
    >
      {{ language === 'de' ? 'Impressum' : 'Imprint' }}
    </span>
    /
    <span
      @click="$router.push('/datenschutz')"
      class="caption ml-1"
      style="cursor:pointer"
    >
      {{ language === 'de' ? 'Datenschutz' : 'Privacy' }}
    </span>
    </v-footer>

    </v-list>
  </v-navigation-drawer>

  <!-- Content -->
    <v-content>
      <v-container class="pa-3">
        <router-view class="mt-8"></router-view>
      </v-container>
    </v-content>
  </v-app>

</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'App',
  data: function () {
    return {
      showNav: false
    }
  },
  mounted: function () {
    if (this.$route.query.language === 'en') {
      this.setLanguage('en')
    }
    this.$router.replace({ query: {} })
  },
  methods: {
    ...mapMutations({
      setLanguage: 'SET_LANGUAGE'
    })
  },
  computed: {
    ...mapGetters([
      'language'
    ])
  }
}
</script>

<style>
  .container {
    max-width: 800px !important;
  }
  a {
    text-decoration: none !important;
    color: rgba(0,0,0,.87) !important;
  }
</style>
