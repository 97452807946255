<template>
  <component :is="computedComponent"></component>
</template>

<script>

import Ziele from '@/views/Ziele'
import ZieleEn from '@/views/Ziele_en'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    Ziele,
    ZieleEn
  },
  computed: {
    ...mapGetters([
      'language'
    ]),
    computedComponent () {
      if (this.language === 'de') {
        return 'Ziele'
      } else {
        return 'ZieleEn'
      }
    }
  }
}
</script>
