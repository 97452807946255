<template>
  <div class="home">
    <v-row>
      <v-col>
        <div class="display-3 font-weight-thin mb-4">
          Team
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          The Ulmer Nest is being developed by an interdisciplinary team, which consists of the founders of two Ulm-based young businesses, and the computer scientist Florian Geiselhart:
        </p>
        <v-img class="my-6" src="/img/team.jpg"></v-img>
        <p>Left to right:</p>
        <p>
          <b>Patrick Kaczmarek</b> Product Design<br>
          <b>Florian Geiselhart</b> Software Development<br>
          <b>Falko Pross</b> Web Development<br>
          <b>Manuel Schall</b> Software Development<br>
          <b>Dirk Bayer</b> Hardware Development<br>
          <b>Kathrin Uhlig</b> User Interface and User Experience Design<br>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <a href="https://bootschaft.de" target="_blank">
          <div class="title mb-3 mt-6">
            Bootschaft
            <v-icon small class="ml-1 mb-1">
              fa fa-external-link-alt
            </v-icon>
          </div>
        </a>
        <p>
          Since being founded in 2013 in Neu-Ulm, Bootschaft serves and advises customers from a wealth of different industries regarding design topics.
        </p>
        <p>
          The interdisciplinary core team, together with a broad and strong network consisting from a number of reliable and qualified partners, allows for the creation of optimized teams for each indiviual project. The working process is solution-oriented and based on a systematical and analytical appproach, while focusing the development of strong and conclusive concepts.
        </p>
        <p>
          The core competence of Bootschaft lies within the design of products and the associated user interface or user experience (UX/UI).
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <a href="https://widerstandundsoehne.de" target="_blank">
          <div class="title mb-6 mt-6">
            Widerstand und Söhne
            <v-icon small class="ml-1 mb-1">
              fa fa-external-link-alt
            </v-icon>
          </div>
        </a>
        <p>
          Widerstand und Söhne ("Resistor and Sons") was founded in 2017 in Ulm, and consists of an interdisciplinary team of computer scientists from the domains of automation, hardware development, software development and usability engineering.
        </p>
        <p>
          The company develops individual, connected hardware and software solutions for the industry, small businesses, arts and culture. Within this, customers are being served throughout the whole process from concept to final product.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <a href="https://florian-geiselhart.de" target="_blank">
          <div class="title mb-6 mt-6">
            Florian Geiselhart
            <v-icon small class="ml-1 mb-1">
              fa fa-external-link-alt
            </v-icon>
          </div>
          <p>
            Florian Geiselhart, being a freelance media computer scientist, advises and developes in the area of interactive digital solutions and concepts, which are mostly situated in the area of user-centered design, technology and art. Currently, he is teaching at the HfG Gmünd as a temporary professor in the area of "Design of Connected Systems".
          </p>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
  }
}
</script>
