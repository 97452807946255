import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeContainer from '../views/HomeContainer.vue'
import FAQContainer from '../views/FAQContainer.vue'
import ThanksContainer from '../views/ThanksContainer.vue'
import ZieleContainer from '../views/ZieleContainer.vue'
import UrsprungContainer from '../views/UrsprungContainer.vue'
import EntwicklungContainer from '../views/EntwicklungContainer.vue'
import TeamContainer from '../views/TeamContainer.vue'
import PresseContainer from '../views/PresseContainer.vue'
import ImpressumContainer from '../views/ImpressumContainer.vue'
import Datenschutz from '../views/Datenschutz.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeContainer
  },
  {
    path: '/ziele',
    name: 'ziele',
    component: ZieleContainer
  },
  {
    path: '/ursprung',
    name: 'ursprung',
    component: UrsprungContainer
  },
  {
    path: '/entwicklung',
    name: 'entwicklung',
    component: EntwicklungContainer
  },
  {
    path: '/team',
    name: 'team',
    component: TeamContainer
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQContainer
  },
  {
    path: '/danke',
    name: 'danke',
    component: ThanksContainer
  },
  {
    path: '/kontakt',
    name: 'presse',
    component: PresseContainer
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: ImpressumContainer
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: Datenschutz
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
