<template>
  <component :is="computedComponent"></component>
</template>

<script>

import Entwicklung from '@/views/Entwicklung'
import EntwicklungEn from '@/views/Entwicklung_en'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    Entwicklung,
    EntwicklungEn
  },
  computed: {
    ...mapGetters([
      'language'
    ]),
    computedComponent () {
      if (this.language === 'de') {
        return 'Entwicklung'
      } else {
        return 'EntwicklungEn'
      }
    }
  }
}
</script>
